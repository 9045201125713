import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import SpotifyPlayer from "react-spotify-player"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const MusicPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const size = {
    width: "100%",
    height: 300,
  }
  const view = "list" // or 'coverart'
  const theme = "black" // or 'white'

  return (
    <Layout title={siteTitle}>
      <SEO title="Music" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Music</h2>
          <p>
            Music has a unique ability to make us feel something very deep and
            intense very suddenly. Here are some of the playlists I made and
            designed cover arts for.
          </p>
          <hr></hr>
          <div className="row">
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:5o3VCmttBHjZwMrO9eM8PE"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:6uyDdMjVmca3I5bqdqwL1A"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:7jf6kRqs9omUMoKNQmb2Ua"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:1UbMhcsWVkd0Ec2Hi7tQkm"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:1gowPwlp2KGvf78xs4pX0E"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:5NU9U0KWaoxzN9rGtBE3Oo"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:6oHVgs8vvOcblzavJUpgME"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:0w3riBtExUge8qdqLKEUS7"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:5jltEaeTUKbs9Oay6m2m9K"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:2onRvLtdcNe2oqzcDrlnUy"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:5ZcN0iUIYwzKTLyXmnWa86"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:7aozWePq8R9Y8kuQAShemf"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:2Ml1xsNLTvXRZrDahzaosf"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:3voRr8s7P9AQ5njXi2iTko"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:4ktf4gAtScjLs1oASJyKKu"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:0eJEGGsIkfWYcwb6kuhJ7O"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:6mizfmL3MXanY3gh9X5wmb"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:2TcEGyN8PGs44cpIqAmrnQ"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:3GWG9HcU6HOyhbRvlh5pGN"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:3P68YUlvS9ZsjmA5UEwlAy"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
            <div className="col-4">
              <SpotifyPlayer
                uri="spotify:playlist:0hD8vryYKKyq4anlzNmhkL"
                size={size}
                view={view}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <MusicPage location={props.location} data={data} {...props} />
    )}
  />
)
